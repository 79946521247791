<template>
    <div class="main-container" dir="ltr">
        <div class="row justify-content-center" >
          <div class="col-xl-12 col-md-7" >
              <img style="display: none" class="viewtube-player-single" src="publish.img" />
                <div class="d-flex content-creator justify-content-between" dir="rtl">
                  <div class="my-auto">
                    <a href="#">
                      <i class="fas fa-volume-up" style="color: #004d4d;"></i></a>
                      <span class="pl-10"> التصنيف - </span>
                  </div>
                  <div class="my-auto">
                    <a href="content.php?ID=<?php echo $data->id; ?>" class="viewtube-btn subscribe" >
                      <svg style="width: 25px;margin-left: 10px;" class="svg-icon" viewBox="0 0 20 20">
							        <path fill="#fff" d="M2.25,12.584c-0.713,0-1.292,0.578-1.292,1.291s0.579,1.291,1.292,1.291c0.713,0,1.292-0.578,1.292-1.291S2.963,12.584,2.25,12.584z M2.25,14.307c-0.238,0-0.43-0.193-0.43-0.432s0.192-0.432,0.43-0.432c0.238,0,0.431,0.193,0.431,0.432S2.488,14.307,2.25,14.307z M5.694,6.555H18.61c0.237,0,0.431-0.191,0.431-0.43s-0.193-0.431-0.431-0.431H5.694c-0.238,0-0.43,0.192-0.43,0.431S5.457,6.555,5.694,6.555z M2.25,8.708c-0.713,0-1.292,0.578-1.292,1.291c0,0.715,0.579,1.292,1.292,1.292c0.713,0,1.292-0.577,1.292-1.292C3.542,9.287,2.963,8.708,2.25,8.708z M2.25,10.43c-0.238,0-0.43-0.192-0.43-0.431c0-0.237,0.192-0.43,0.43-0.43c0.238,0,0.431,0.192,0.431,0.43C2.681,10.238,2.488,10.43,2.25,10.43z M18.61,9.57H5.694c-0.238,0-0.43,0.192-0.43,0.43c0,0.238,0.192,0.431,0.43,0.431H18.61c0.237,0,0.431-0.192,0.431-0.431C19.041,9.762,18.848,9.57,18.61,9.57z M18.61,13.443H5.694c-0.238,0-0.43,0.193-0.43,0.432s0.192,0.432,0.43,0.432H18.61c0.237,0,0.431-0.193,0.431-0.432S18.848,13.443,18.61,13.443z M2.25,4.833c-0.713,0-1.292,0.578-1.292,1.292c0,0.713,0.579,1.291,1.292,1.291c0.713,0,1.292-0.578,1.292-1.291C3.542,5.412,2.963,4.833,2.25,4.833z M2.25,6.555c-0.238,0-0.43-0.191-0.43-0.43s0.192-0.431,0.43-0.431c0.238,0,0.431,0.192,0.431,0.431S2.488,6.555,2.25,6.555z"></path>
						          </svg> تشغيل</a>
                   </div>
                </div>
                <div class="entry-content">
                  <h1 class="video-entry-title">اسم القسم </h1>
                  <p>وصف القسم </p>
                </div>
          </div>
        </div>
        <div class="row mb-4">
            <div  class="col-md-12 my-auto">
                <h5 style="text-align: right;margin-top: 15px;">كل المحتوي </h5>
            </div>
        </div>
        <div class="row justify-content-center " dir="rtl"  data-slick='{"slidesToShow": , "slidesToScroll": }'>                        
            <div class="col-6 col-sm-4 col-lg-4 col-xl-3" v-for="publish in AllCourses" :key="publish.id">
                <div class="video-item-card">
                    <a @click="GoToSeriesContent(publish)">
                        <div class="video-thumb">
                            <img v-lazy="publish.img" class="custom-logo" alt="Sayara">
                        </div>
                    </a>
                    <div class="video-content">
                        <div class="d-flex">
                            <div class="my-auto ">
                                <a @click="GoToSeriesContent(publish)">
                                    <h5>{{publish.name_ar}}</h5>
                                </a>
                                <ul class="list-inline">
                                    <li class="list-inline-item">
                                        <a class="author " @click="GoToSeriesContent(publish)">
                                            {{publish.cp_name}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { ref } from '@vue/reactivity';
import { useRouter } from "vue-router"
import { useCookie } from 'vue-cookie-next'
export default {
 name:'AppVocabulary',
 async setup() {
    const router = useRouter();
    const cookie = useCookie();
    // const toast = useToast();
    const AllCourses = ref([]);
    
    try {
      await HTTP.get(`GetAllCourses.php`).then((res) => {
        AllCourses.value = res.data.Courses; 
      });
    } catch (err) {
      console.log(err);
    }
    const GoToSeriesContent = (publish) => {
        router.push({ name: "SeriesContent", params: { series_id: publish.id } });
    };

    return { AllCourses ,GoToSeriesContent };
    
  },

}
</script>

<style>

</style>